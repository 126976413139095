<template>
  <div v-if="info">
     <div>
      <Cabecera :ruta="'/back'"  />
  </div>
  <div class="mensajeNOk">
      <p class="titulo_nok">{{info.textoPrimero}}</p>
      <p class="texto_m">{{info.textoSegundo}}</p>
  </div>
  <div class="divBoton">
      <v-btn
      depressed
      color="#019df4"
      class="botonColor"
      @click="salir"
        >{{info.boton}}
      </v-btn>
  </div>
</div>
</template>

<script>
import Cabecera from "./Cabecera";
import router from "./../router/index";
import axios from "axios";
export default {
  data: function () {
    return {
      params: {},
      isMounted: false,
    //  items: this.$i18n.t('nOk'),
      info:null,
    }
   
  },
  components: {
    Cabecera
  },
  mounted: function(){
      this.isMounted = true;
      axios
      .get('https://cloud.movistar.es/webAyuda/Config.json')
      .then(response => {
        
        this.info = response.data.textos.nOk;
      })
    if(localStorage.getItem("paramsForm")){
        var params= JSON.parse(localStorage.getItem("paramsForm"))
        if(params.params &&(params.params.asunto.texto==undefined|| params.params.asunto.tipo ==undefined)){
            router.push({ path: '/'});
        }
      }else{
           router.push({ path: '/'});
      }

    
  },
  methods:{
    salir (){
        location.href=this.info.salir;
    }
  },


}
</script>

<style>
.titulo_nok{
        font-size: 2.5rem;
        color: #d8d8d8;
}
.botonColor{
    color: white !important;
     display:block;
  margin-left: auto;
  margin-right: auto;
  width: 240px;
}
.mensajeNOk{
    padding: 50px;
    text-align: center;
}
.texto_m{
    font-family: "Telefonica-Light";
  font-size: 22px;
  margin: 20px 0px;
  width: 100%;
}
.v-btn:not(.v-btn--round).v-size--default {
    height: 50px;
    min-width: 64px;
    padding: 0 16px;
}
.divBoton{
  height: 160px;
}
.v-btn {
  text-transform: none;
  font-size: 20px !important;
}
</style>