<template>
<div v-if="info">
  <div>
  <Cabecera :ruta="'/salir'" />
  </div>
<div class=" m-left">
  <div class="">
    <p class="texto_m">{{info.texto}}</p>
    <p class="titulo_m verde">{{info.titulo}}</p>
  </div>
<ul id="example-1">
  <li v-bind:key="menu.texto" v-for="menu in menus">
    <router-link class ="texto_m opciones" :to="{ name: 'formulario_corto', params:{tipo: menu.tipo, texto: menu.texto}}">{{ menu.texto }}</router-link>
  </li>
</ul>

</div>
</div>
</template>

<script>
import Cabecera from "./Cabecera"
import axios from "axios";
import router from "./../router/index";
export default {
   
  components:{
    Cabecera
  },
   mounted() {
      localStorage.removeItem('paramsForm');      
      axios
        .get('https://cloud.movistar.es/webAyuda/Config.json')
      .then(response => {
        
        this.info = response.data.textos.home;
        this.timeout=response.data.textos.home.timeoutMenu;
        this.backUrl=response.data.textos.back.url+"api/asunto/getAsuntos";
      
        axios.get(this.backUrl,{timeout: parseInt(this.timeout)})
         .then(response => {
         var conversion=JSON.stringify(response.data.data)
         var asuntos=JSON.parse( conversion)
         this.menus =JSON.parse( asuntos).asuntos;
      })
       .catch(error => {
         var params={
           "code": "error"
         }
         localStorage.setItem("paramsForm",JSON.stringify(params ))
        router.push({ path: 'sendNOk'});
        console.log(error)
      });
      })
  },
  name: 'Menu',

   data: function () {
    return {
      info:null,
      menus:null,
      //items: this.$i18n.t('opciones'),
      backUrl:null,
      timeout:null,
      title:null,
    };

    },
    metaInfo() {
        return {
            title: this.title,
          
        };
    },
methods:{
}


}


</script>

<style>
.titulo_m{
  font-family: "Telefonica-Light";
  font-size: 36px;
   margin: 9px 0px 9px 0px;
}
.texto_m{
    font-family: "Telefonica-Light";
  font-size: 22px;
  margin: 20px 0px 0px 0px;
  width: 100%;

}
.opciones{
   line-height: 40px;
}
@media (max-width: 812px) {
  .texto_m{
    font-size: 15px !important;
  }
  .titulo_m{
    font-size: 25px !important;
  }
  .opciones {
    line-height: 36px;
}
}
</style>