import Vue from 'vue';
import VueRouter from 'vue-router';
import Menu from "../components/Menu.vue";
import Form_type_one from "../components/Form_type_one.vue";
import VistaOk from "../components/OK.vue";
import VistaNOk from "../components/NoK.vue";
Vue.use(VueRouter)
const routes = [{
        path: "/",
        name: "home",
        props: true,
        component: Menu,
    },
    {
        path: "/form:tipo",
        name: "formulario_corto",
        props: true,
        component: Form_type_one,
    },
    {
        path: "/sendOk",
        name: "envioOk",
        component: VistaOk,
    },
    {
        path: "/sendNOk",
        name: "envioNOk",
        component: VistaNOk,
    },
    {
        path: "*",
        component: Menu,
    }
]

const router = new VueRouter({
    routes
})

export default router;