<template >
  <div id="Captcha" v-if="info">
        <vue-hcaptcha
            size="normal"
            :sitekey="this.info.codigo"
            tabindex=50
            @verify="onVerify"
            @expired="onExpire"
            @error="onError"
            @challengeExpired="onExpire"
        />
        
          <div v-if="verified" id="verified">
            <p class="verified"></p>
        </div>

        <div v-if="expired" id="expired">
             <p class="errorExpired">{{info.expirado}}</p>
        </div>
          <div v-if="error" id="error">
           <p class="errorExpired">{{info.error}}</p>
        </div>

    </div>

</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import axios from "axios";
export default {
name: 'Captcha',
props:['verifiedProp'],
    components: {
        VueHcaptcha
    },
    data() {
        return {
            verified: false,
            expired: false,
            token: null,
            eKey: null,
            error: null,
            info:null,
        }
    },
      mounted() {
      axios
        .get('https://cloud.movistar.es/webAyuda/Config.json')
      .then(response => {
        
        this.info = response.data.textos.captcha;
      })
  },
    methods: {
        onVerify(token, ekey) {
            this.verified = true;
            this.token = token;
            this.eKey = ekey;
            this.$emit('clicked', this.token)
            var divExpire= document.getElementById("expired");
            var divError= document.getElementById("error");
            document.getElementById("Captcha").classList.remove("checkbox_error");
            if(divExpire){
                document.getElementById("expired").classList.add("hide");
            }else if (divError){
                  document.getElementById("error").classList.add("hide");
            }
        },
        onExpire() {
            this.verified = false;
            this.token = null;
            this.eKey = null;
            this.expired = true;
            this.$emit('clicked', "")
            var divExpire= document.getElementById("expired");
            if(divExpire){
                document.getElementById("expired").classList.remove("hide");
                document.getElementById("Captcha").classList.add("checkbox_error");
            }
        },
        onError(err) {
            this.token = null;
            this.eKey = null;
            this.error = err;
            this.$emit('clicked', "")
           var divError= document.getElementById("error");
            if(divError){
                document.getElementById("error").classList.remove("hide");
                document.getElementById("Captcha").classList.add("checkbox_error");
            }
        }
    }
}
</script>

<style>
.hide{
    display: none;
}
body {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#Captcha {
    display: table;
    flex-direction: column;
    margin: auto;
    padding: 0;
}
#verified {
    color: white;
    background: green;
}
.errorExpired {
    font-size: 12px;
    color: #ff5252 !important;
    margin-bottom: 10px;
}
.checkbox:focus{
 border: 1px solid red !important;
}
#checkbox {
    border: 1px solid red;
}
.checkbox_error {
    border: 1px solid red;
}
</style>