<template>
<div>
  <hr class="arriba">
  <hr class="abajo">
  <div id="app" class="container_app">
    <div id="Cabecera">
    </div>
    <router-view/>
  </div>
  </div>
</template>
<script>
export default {
  name: 'app',
  components: {
  },
}
</script>

<style>
.arriba{
  margin-top: 80px;
  border: 1px solid rgb(0 0 0 / 10%);
}
.abajo{
  margin-top: 80px;
  border: 1px solid rgb(0 0 0 / 10%);
}
.container_app{
  margin-top: -160px !important
}

        @font-face {
            font-family: "Telefonica";
            src: url(./assets/OnAir-Regular.woff) format("woff");
        }
        
        @font-face {
            font-family: "Telefonica-Light";
            src: url(./assets/OnAir-Light.woff) format("woff");
        }
        
        p,
        a,
        body,
        textarea,
        input,
        hr {
            font-family: "Telefonica-Light" !important;
            margin-block-start: 0px;
            margin-block-end: 0px;
        }
        .v-application {  
            font-family: "Telefonica-Light";
        }
        .telefonica_L {
            font-family: "Telefonica-Light";
        }
        .icono_sgv {
          fill: #019df4;
        }
        a {
    text-decoration: none;
    color: #019df4;
}
#app {
    width: 1224px;
    margin: 0 auto;
}

.m-left {
    margin-left: 24px;
    margin-right: 24px;
}

.m-left_1 {
    margin-left: 104px;
}

.verde {
    color: #499010;
}

.float-left {
    float: left;
}
ul {
    list-style: none;
    padding-inline-start: 0px;
}

.checkbox:focus{
 border: 1px solid red !important;
}
.azul {
    color: #019df4;
}
@media (max-width: 1220px) {
  #app {
      width: 100%;
      
  }
}
</style>
