<template>
<div v-if="info">
 <div>
  <Cabecera :ruta="'/'" />
  </div>
  <div class="componente">
     
  <div class="textos_form">
    <p class="texto_m">{{info.texto}}</p>
    <p class="titulo_m verde">{{info.titulo}}</p>
      
  </div>
<v-form
ref="form"
    v-model="valid"
    lazy-validation>
   <v-app >
     <v-container>
       <fieldset>
         <legend>{{this.info.input.legendPersonales}}</legend>
       <v-row class="rowPrimera">
         <v-col cols = "12" md = "6" lg = "6" xl = "6">
           <v-text-field
      :label="this.info.input.nombre"
      v-model="nombre"
      ref="nombreRef"
      :rules="nombreRules"    
      maxlength="25"
      required
    ></v-text-field>
   
         </v-col>
         <v-col cols = "12" md = "6" lg = "6" xl = "6">
   <v-text-field 
    :label="this.info.input.apellidos"
    ref="apellidosRef"
    v-model="apellidos"
    :rules="apellidosRules"
    maxlength="50" 
    required
    ></v-text-field>
         </v-col>
       </v-row>
       <v-row class="rowDocumento">
         <v-col cols = "12" md = "6" lg = "6" xl = "6">
     <v-radio-group v-model="radioGroup" mandatory>
      <v-radio
        @click="actualiza"
        v-for="n in 3"
        :key="n"
        :label="` ${info.input.tipoDoc[n-1]}`"
        :value="n"
      ></v-radio>
    </v-radio-group>
  
         </v-col>
      
         <v-col cols = "12" md = "6" lg = "6" xl = "6" class="numeroDocu">
           <v-text-field 

          ref="numDocuRef"
    :label="this.info.input.numDoc"
   v-model="numDoc"
    maxlength="9"
  @keyup="uppercase"
   :rules="numDocRules"
    required
    ></v-text-field>
         </v-col>
       </v-row>
              <v-row class="rowNumeros">
         <v-col cols = "12" md = "6" lg = "6" xl = "6">
           <v-text-field
      :label="this.info.input.movilContacto"
      v-model="movilContacto"
      :rules="movilContactorules"
      ref="movilContactoRef"
      maxlength="9"
      required
    ></v-text-field>
   
         </v-col>
         <v-col cols = "12" md = "6" lg = "6" xl = "6">
   <v-text-field 
    :label="this.info.input.emailContacto"
    @keyup="lowecaseEmail"
     maxlength="50"
     ref="emailContactoRef"
    v-model="emailContacto"
    :rules="emailContactoRules"
    required
    ></v-text-field>
         </v-col>
       </v-row>
              <v-row class="rowFinal" v-if=" $route.params.tipo==2">
         <v-col cols = "12" md = "6" lg = "6" xl = "6">
           <v-text-field
           v-model="movilCoud"
      :label="this.info.input.numeroCloud"
       :rules="movilCloudRules"
       ref="movilCloudRef"
     maxlength="9"
      required
    ></v-text-field>
   
         </v-col>
         <v-col cols = "12" md = "6" lg = "6" xl = "6">
   <v-text-field 
   v-model="emailCloud"
    :label="this.info.input.emailCloud"
     @keyup="lowecaseEmailCloud"
    :rules="emailCloudRules"
    maxlength="50"
    ref="emailCloudRef"
    required
    ></v-text-field>
         </v-col>
       </v-row>
       </fieldset>
       <fieldset>
         <legend>{{this.info.input.legendAcceso}}</legend>
         <v-row class="rowPrimera">
         <v-col cols = "12" md = "6" lg = "6" xl = "6">
           <v-text-field
           v-model="version"
      :label="this.info.input.dispositivo+ this.info.input.opcional"
      ref="navegadorRef"
      :rules="navegadorRules"
      :hint="this.info.input.navegador"
      maxlength="50"
      required
    ></v-text-field>
   
         </v-col>
         <v-col cols = "12" md = "6" lg = "6" xl = "6">
   <v-text-field 
   v-model="sistemaOperativo"
   :label="this.info.input.sistemaOperativo+this.info.input.opcional"
   :rules="sistemaRules"
   ref="sistemaRef"
    :hint="this.info.input.version"
    maxlength="50"
    required
    ></v-text-field>
         </v-col>
       </v-row>
         </fieldset>
       <fieldset class="baja_consulta">
         <legend>{{this.info.input.legendConsulta}}</legend>
       <v-row>
         <v-col >          
          <v-textarea
           
           outlined
            required
            ref="consultaRef"
            v-model.trim="consulta"
            :rules="consultaRules"
            name="consultaName"
            :placeholder="this.info.input.comentario"
            :counter="2000"
            maxlength="2000"
            auto-grow
            value=""
          ></v-textarea>
        </v-col>
      </v-row>
      </fieldset>
    </v-container>
  </v-app>
   <v-row>
    <v-col cols = "12" md = "6" lg = "6" xl = "6">
      <div class="catpchaDiv">
  <Captcha  @clicked="onClickChild" />
  </div>
  </v-col>
  <v-col cols = "12" md = "6" lg = "6" xl = "6">
       <div class="divBoton">
      <v-btn
      depressed
      :disabled="!completo"
      color="#019df4"
      class="botonColor"
      @click="enviar"
        >{{this.info.input.boton}}
      </v-btn>
  </div>
    </v-col>
      </v-row>
</v-form>

  </div>
  </div>
</template>

<script>
import Captcha from './Captcha';
import Cabecera from "./Cabecera"
import axios from "axios";
import router from "./../router/index"
export default {
  components:{
    Captcha,Cabecera
  },
    name: 'Form_type_one',
    data: function () {
    return {
    info:null,
    tipoMenu: this.$route.params.tipo,
    textoMenu: this.$route.params.texto,
    paramsUrl: this.$route.params.params,
    captchaRef: false,
    isMounted: false,
    valid:true,
    back:null,
   // items: this.$i18n.t('input'),
    nombre:'',
    apellidos:'',
    movilContacto:'',
    emailContacto: '',
    consulta :'',
    radioGroup: null,
    movilCoud:'',
    sistemaOperativo:'',
    version:'',
    emailCloud:'',
    numDoc: '',
    captchaToken:'',
    nombreRules:[
      v => !!v || this.info.input.error.no_nombre,
      v => this.verificarSinEspacios(v, "nombreRef")  || this.info.input.error.nombre,
    ],
    apellidosRules:[
      v => !!v || this.info.input.error.no_apellidos,
      v => this.verificarSinEspacios(v,"apellidosRef") || this.info.input.error.apellidos,
    ],
    movilContactorules:[
      v => !!v || this.info.input.error.no_movilContacto,
      v => (/^[67][0-9]{8}$/).test(v)|| this.info.input.error.movilContacto,
    ],
        
    emailContactoRules: [
      v => !!v || this.info.input.error.no_emailContacto,
      v => v.length<50 || this.info.input.error.emailContacto,
      v => (/^[a-z0-9!#$'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(v)) || this.info.input.error.emailContacto,
    ],
    movilCloudRules:[
      v => !!v || this.info.input.error.no_numeroCloud,
      v => (/^[67][0-9]{8}$/).test(v)|| this.info.input.error.numeroCloud,
    ],
        
    emailCloudRules: [
      v => !!v || this.info.input.error.no_emailCloud,
      v => v.length<50 || this.info.input.error.emailCloud,
      v => (/^[a-z0-9!#$'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(v)) || this.info.input.error.emailCloud,
    ],
    consultaRules:[
      v => !!v || this.info.input.error.no_consulta,
      v => this.verificarSinEspacios(v,"consultaRef")|| this.info.input.error.consulta,
    ],
    numDocRules:[
      v => !!v || this.info.input.error.no_numDoc,
      v => this.valNumDoc(v)|| "El "+this.info.input.tipoDoc[this.radioGroup-1]+this.info.input.error.numDoc,
    ],
    navegadorRules:[
      v=> this.verificarSinEspacios(v,"navegadorRef")|| this.info.input.error.dispositivo,
    ],
    sistemaRules:[
      v=> this.verificarSinEspacios(v,"sistemaRef")|| this.info.input.error.sistemaOperativo, 
    ]

    };
    
    },
 methods: {
   enviar() {
     let config={
       headers:{
         "Content-Type":"application/json",
         "Accept": " */*",
         "Access-Control-Allow-Credentials": true,
       },
       timeout:this.info.input.timeout
     };
     var params={
       
        "nombre": this.nombre,
        "apellidos": this.apellidos,
        "telefono": this.movilContacto,
        "email": this.emailContacto,
        "telefonoCloud": this.movilCoud,
        "emailCloud": this.emailCloud,
        "documento": {
            "tipo": this.info.input.tipoDoc[this.radioGroup-1],
            "numero": this.numDoc
        },
        "sistemaOperativo": this.sistemaOperativo,
        "version":this.version,
        "asunto": {
            "tipo": this.tipoMenu,
            "texto": this.textoMenu,
        },
        "comentarios": this.consulta,
        "token": this.captchaToken,
      };
      var tipoDeDocumento={
        "tipoDeDocumento": this.radioGroup
      };
      var localParams= {params,tipoDeDocumento}
      localStorage.setItem("paramsForm", JSON.stringify(localParams));
    axios
      .post(this.back+"api/email/sendEmail",params,config)
      .then(result => {
        this.result = result.data;
        router.push({ path: 'sendOk'});
      })
      .catch(error => {
        router.push({ path: 'sendNOk',params: params });
        console.log(error);
      });
    },
   onClickChild(value){
    
     if(value.length>0){
       this.captchaToken=value;
       this.captchaRef=true;
     }else{
       this.captchaRef=false;
     }
   },
   verificarSinEspacios(input, ref){
     var referencia='';
     var tipo=0;
   if(ref=="nombreRef"){
     referencia= this.$refs.nombreRef;
     tipo=1;
     
   }else if( ref=="apellidosRef"){
     tipo=1;
     referencia= this.$refs.apellidosRef;
   }else if(ref=="navegadorRef"){
     tipo=2;
     referencia=this.$refs.navegadorRef;
   }else if(ref=="sistemaRef"){
     tipo=2;
     referencia=this.$refs.sistemaRef;
   }else if(ref=="consultaRef"){
     tipo=3;
     referencia=this.$refs.consultaRef;
   }
      if(input && input.trim() =="")
      {
        referencia.reset()
        if(tipo==1){
          return false;
        }else if (tipo==2||tipo==3){
          return true;
        }
      }else if((/^[A-Za-záéíóúüÜñÑÁÉÍÓÚçÇ\-' ]*$/).test(input)&&tipo==1){
        return true;
      }else if((/^[A-Za-záéíóúüÜñÑÁÉÍÓÚçÇ0-9.@,.;:!¡%?¿_()\-' ]*$/).test(input)&&tipo==2){
        return true;
      }else if((/^[A-Za-záéíóúüÜñÑÁÉÍÓÚçÇ0-9.@,.;:!¡%?¿_()\-'\n ]*$/).test(input)&&tipo==3){
        return true;
      }
   },
   actualiza(){
     this.$refs.numDocuRef.validate()
   },
  
   uppercase(){
     this.numDoc=this.numDoc.toUpperCase()
   },
   lowecaseEmail(){
     this.emailContacto=this.emailContacto.toLowerCase();
   },
   lowecaseEmailCloud(){
         this.emailCloud=this.emailCloud.toLowerCase();
   },
      submit () {
        this.valid= this.$refs.form.validate()
      },
      valNumDoc(num){
        var tipo= this.radioGroup;
    
     if(tipo==1){
        if(/^\d{8}[a-zA-Z]$/.test(num)){
          var letraDNI = num.substring(8, 9).toUpperCase();
          var numDNI = parseInt(num.substring(0, 8));
          var letras = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E', 'T'];
          var letraCorrecta = letras[numDNI % 23];

          if(letraDNI!= letraCorrecta){
           return false;
           } else {
            return true;

          }
        }else{
          return false
        }
     }else if(tipo==2){
       if(num.length==9)
       {return true
       }else{
         return false
       }

     }else if(tipo==3){
       if(/^[XxTtYyZz]{1}[0-9]{7}[a-zA-Z]{1}$/.test(num)){
        num = num.toUpperCase();
         var numero = num.substr(0,num.length-1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        var letraDni = num.substr(num.length-1, 1);
        numero = numero % 23;
        var letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero+1);
        if (letra != letraDni) {
            return false;
        }else{
            return true;
        } 
       }else{
         return false;
       }
     }
      }
    
},
mounted: 

  function(){
    this.isMounted = true;
    axios
        .get('https://cloud.movistar.es/webAyuda/Config.json')
      .then(response => {
         
        this.info = response.data.textos.form;
        this.back = response.data.textos.back.url;
      })
   if(localStorage.getItem("paramsForm")){
     
      this.paramsLocal=JSON.parse(localStorage.getItem("paramsForm"))
     
      this.nombre=this.paramsLocal.params.nombre;
      this.apellidos=this.paramsLocal.params.apellidos;
      this.movilContacto=this.paramsLocal.params.telefono;
      this.emailContacto=  this.paramsLocal.params.email;
      this.movilCoud= this.paramsLocal.params.telefonoCloud;
      this.emailCloud=this.paramsLocal.params.emailCloud;
     
      this.numDoc= this.paramsLocal.params.documento.numero;
      this.sistemaOperativo =  this.paramsLocal.params.sistemaOperativo;
      this.version= this.paramsLocal.params.version;
      this.consulta= this.paramsLocal.params.comentarios;
      this.textoMenu= this.paramsLocal.params.asunto.texto;
      this.tipoMenu= this.paramsLocal.params.asunto.tipo;
      this.radioGroup= this.paramsLocal.tipoDeDocumento.tipoDeDocumento;
      this.captchaToken='';     
    }
        if(   this.tipoMenu==undefined ||this.textoMenu==undefined){
       router.push({ path: '/'});
       
     }
  
  },
     
computed: {
    completo: function(){
      if( this.isMounted){
  var formularioVacio=(this.$refs.nombreRef==undefined || this.$refs.nombreRef.value !="")&&(this.$refs.apellidosRef==undefined || this.$refs.apellidosRef.value !="")&&(this.$refs.numDocuRef==undefined || this.$refs.numDocuRef.value !="")&&(this.$refs.movilContactoRef==undefined || this.$refs.movilContactoRef.value !="")&&(this.$refs.consultaRef==undefined || this.$refs.consultaRef.value !="")&&this.valid&&this.captchaRef;
      if(this.$route.params.tipo==2){
        return formularioVacio&&(this.$refs.emailCloudRef==undefined || this.$refs.emailCloudRef.value !="")&&(this.$refs.movilCoudRef==undefined || this.$refs.movilCoudRef.value !="");
      }else{
        return  formularioVacio;
      }
      }else{
        return false;
      }
    },

},

}
</script>

<style>
.catpchaDiv{
  margin-right: -50px;
}
.botonColor{
    color: white !important;
     display:block;
  margin-left: auto;
  margin-right: auto;
  width: 240px;
}
.v-input--selection-controls__input input[role=radio], .v-input--selection-controls__input input[role=switch] {
    
    opacity: 1
    }
    .v-application--wrap {
      min-height: 0px !important;
    }
    fieldset{
      padding: 20px;
          margin-bottom: 20px;
    }
    legend{
      padding: 0px 20px;
    }
    .titulo_m{
  font-family: "Telefonica-Light";
  font-size: 36px;
   margin: 9px 0px 9px 0px;
}
.texto_m{
    font-family: "Telefonica-Light";
  font-size: 22px;
  margin: 20px 0px 0px 0px;
  width: 100%;

}
.componente{
  margin-top: 160px;
  margin-left: 24px;
  margin-right: 24px;
}
.textos_form {
    margin-top: -140px;
}
.upercase .v-text-field input{
  text-transform: uppercase !important;
}
.col{
      padding: 6px 12px;
}
.row+.row {
  margin-top: 0px;
}
.v-btn:not(.v-btn--round).v-size--default {
    height: 50px;
    min-width: 64px;
    padding: 0 16px;
}
.divBoton{
  height: 75px !important;
  margin-top: 14px;
  margin-left: -50px;
}
.v-btn {
  text-transform: none;
  font-size: 20px !important;
}
.numeroDocu{
  margin-top: 28px;
}
.tipo_doc{
  margin-top: 20px;
  
}
.v-application {
    width: 100% !important;
}
.rowNumeros{
  margin-top: -25px!important;
}
.rowDocumento{
  margin-top: -26px !important;
}
.rowPrimera{
  margin-top: -21px !important;
}
.rowFinal{
  margin-top: 2px!important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-top: -18px;
}
.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-bottom: 20px;
}
.theme--light.v-counter {
    color: rgba(0, 0, 0, 0.6) !important;
}
@media (max-width: 1023px) {
  #app{
    width: 100%;
  }
  .numeroDocu{
     margin-top: -20px;
  }

   .catpchaDiv{
    margin-right: 0px !important;  
  }
  .divBoton{
    margin-left: 0px !important;  
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2.75){
    
}
@media (min-width: 960px){
  .numeroDocu{
     margin-top: 28px;
  }
  
}
@media (max-width: 960px){
  .divBoton{
    margin-top: 0px !important;  
  }
    .numeroDocu{
    margin-top: -25px !important;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio:3)  and (-webkit-min-device-pixel-ratio: 2.75)  {
  .catpchaDiv{
    margin-right: 0px;  
  }
  .divBoton{
    margin-left: 0px;  
    margin-top: 0px !important;
  }

  textarea::placeholder {
    font-size: 12px;
}
  .v-input input:focus, .v-input input:active, .v-input textarea:focus, .v-input textarea:active {
    font-size: 14px;
}
  .v-label {
    font-size: 12px !important;
  }
  .v-messages__message.message-transition-enter-to {
    font-size: 9px !important;
}
  input{
    font-size: 15px;
  }
  .texto_m{
    font-size: 15px;
  }
  .titulo_m{
    font-size: 29px;
  }
  #app{
    width: 812px;
  }
    .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding: 0px !important;
}
.rowDocumento{
  padding-top: 15px !important;
    padding-bottom: 20px !important;
}
  .rowNumeros{
    margin-top: -15px !important;
  }
  .rowFinal{
    margin-top: 15px !important;
  }
}
@media  (max-width: 400px)  {
   .rowNumeros{
    margin-top: 0px !important;
  }
}
@media (max-width: 375px) {
  #app{
    width: 375px;
  }
  .container{
    padding: 0px !important;
  }
}
</style>