<template >
<div v-if="info">
  <div class="altura_div">
    <div class="colu-1 alinedo_vertical">
       <svg focusable="false" viewBox="0 0 200 200" color="" aria-label="" aria-hidden="true" role="presentation" class="icono_sgv" style="user-select: none; display: inline-block; min-width: 2.75rem; width: 2.75rem; min-height: 2.75rem; height: 2.75rem;"><path d="M199.4,101.8c0,7.7-0.5,14.8-1.9,24.5c-0.4,2.8-1.1,6.8-2,9.4c-2.4,7.4-8.6,14.2-17.3,13c-6.8-1-11.1-6.5-11.7-15 c-0.1-2.2,0-5.7,0.5-10.7c0.8-7.7,1.6-15.9,1.6-23.5c-0.1-10.2-2.2-13-5.2-14c-4.3-1.5-11,5.5-15.8,16.6c-4.3,9.9-7.3,19.6-11,31.9 l-0.2,0.8c-0.6,1.9-1.2,3.9-1.8,6c-6.2,20.4-18.2,29.9-38.8,30.8c-19.9,0.8-30.8-12.8-35.6-21.1c-3.8-6.6-8.1-14.4-12.2-21.9l-1.6-3 c-1.6-2.8-3-5.4-4.2-7.5c-4.3-7.8-6.7-9.1-7.8-8.9c-1.6,0.4-3.7,0.8-3.4,8.9c0.2,6.5,3,15.8,5.5,24c0.4,1.4,0.8,2.7,1.1,3.9 c1.7,5.5,2.7,9.8,3.2,12.9c1.6,10.6-3.6,15-6.9,16.7c-1.6,0.8-3.7,1.2-5.8,1.2c-4.6,0-8.7-2-11.4-5.5c-1.3-1.6-3.2-4.7-4.5-7.8 C10.7,159.3,5,144.7,2,125.7c-2.6-15.9-2-30.8,1.8-46.8c8-33.8,26.3-39.1,36.4-39.3h0.5c12.1,0,18.2,6.3,23.1,11.3l0.6,0.6 C70,57.3,79.9,73.9,84,80.8c3.1,5.2,9.4,13.8,17.7,12.9c5.1-0.6,8.8-3.9,11.5-10.4c1-2.5,2.3-5.8,3.7-9.6l0.4-1.1 c2.8-7.3,6.3-16.3,10.3-25.2c7.3-15.5,21.4-25.4,35.4-24.4c11.4,0.8,20.9,8.8,26.7,22.7c5.3,12.7,8.5,27.5,9.5,44.2 C199.3,94.2,199.4,98.1,199.4,101.8z"></path></svg>
    </div>
    <div class="colu-8 m-left alinedo_vertical">
        <p class="titulo_header alinedo_vertical"><strong>{{info.titulo}}</strong></p>
    </div>
    <div class="colu-3  m-left m_left_10 aling_right alinedo_vertical">
    <a href="public/ayuda.pdf" download="Guía de ayuda y solución de problemas de Movistar Cloud.pdf"><svg focusable="false" viewBox="0 0 200 200" color="019df4" aria-label="" aria-hidden="true" role="presentation" class="color_search" style="user-select: none; display: inline-block; min-width: 1rem; width: 1rem; min-height: 1rem; height: 1rem;"><path d="M198.7,194.8l-61.5-61.5c13.7-14.7,21.1-33.6,21.1-53.8c0-21.1-8.2-40.9-23.1-55.8C120.3,8.7,100.5,0.5,79.4,0.5 S38.5,8.7,23.6,23.6S0.4,58.3,0.4,79.4s8.2,40.9,23.1,55.8s34.7,23.1,55.8,23.1c20.1,0,39.1-7.5,53.7-21.2l61.5,61.5 c0.5,0.6,1.3,0.8,2,0.8s1.4-0.3,2-0.8C199.9,197.7,199.9,195.9,198.7,194.8z M27.6,131.3C13.8,117.5,6.2,99,6.2,79.4 s7.6-38,21.5-51.8C41.4,13.8,59.8,6.2,79.4,6.2s38,7.6,51.8,21.5c13.8,13.8,21.5,32.2,21.5,51.8s-7.6,38-21.5,51.8 c-13.8,13.7-32.2,21.4-51.8,21.4S41.4,145.1,27.6,131.3z"></path></svg></a>
    <a href="public/ayuda.pdf" class="enlacePdf d-sm-none" download="Guía de ayuda y solución de problemas de Movistar Cloud.pdf">{{info.pdfTexto}}</a>
    </div>
    
      
  </div>

  <div class="altura_no_div">
    
         <div v-if="ruta=='/back'">
             <a @click="$router.go(-1)" class="margen_titulo colu-1 float-left link-active">
            <svg focusable="false" class="colorFlecha" viewBox="0 0 200 200" color="" aria-label="" aria-hidden="true" role="presentation"  style="user-select: none;margin-top: 31px; display: inline-block; min-width: 1rem; width: 1rem; min-height: 1rem; height: 1rem;"><path class="st0" d="M64,100.1l84,89.2c2.3,2.3,2.3,6.1,0,8.4c-2.1,2.4-5.8,2.4-8.1,0l-88-93.4c-2.1-2.4-2.1-6.1,0-8.6l88-93.4 c1.1-1.2,2.6-1.8,4.1-1.8c1.4,0,2.8,0.6,4,1.8c2.3,2.3,2.3,6.1,0,8.6L64,100.1z"></path></svg>
             </a>
         
        </div>
        <div v-else-if="ruta=='/salir'">
             <a @click="salir" class="margen_titulo colu-1 float-left link-active">
             <svg focusable="false" class="colorFlecha" viewBox="0 0 200 200" color="" aria-label="" aria-hidden="true" role="presentation"  style="user-select: none;margin-top: 31px; display: inline-block; min-width: 1rem; width: 1rem; min-height: 1rem; height: 1rem;"><path class="st0" d="M64,100.1l84,89.2c2.3,2.3,2.3,6.1,0,8.4c-2.1,2.4-5.8,2.4-8.1,0l-88-93.4c-2.1-2.4-2.1-6.1,0-8.6l88-93.4 c1.1-1.2,2.6-1.8,4.1-1.8c1.4,0,2.8,0.6,4,1.8c2.3,2.3,2.3,6.1,0,8.6L64,100.1z"></path></svg>
             </a>
            
        </div>
        <div v-else-if="ruta=='/'">

            <router-link class="margen_titulo colu-1 float-left"  :to="ruta">
         <svg focusable="false" class="colorFlecha" viewBox="0 0 200 200" color="" aria-label="" aria-hidden="true" role="presentation"  style="user-select: none;margin-top: 31px; display: inline-block; min-width: 1rem; width: 1rem; min-height: 1rem; height: 1rem;"><path class="st0" d="M64,100.1l84,89.2c2.3,2.3,2.3,6.1,0,8.4c-2.1,2.4-5.8,2.4-8.1,0l-88-93.4c-2.1-2.4-2.1-6.1,0-8.6l88-93.4 c1.1-1.2,2.6-1.8,4.1-1.8c1.4,0,2.8,0.6,4,1.8c2.3,2.3,2.3,6.1,0,8.6L64,100.1z"></path></svg>
            </router-link>
        </div>
        <div>
       
        </div>
    </div>
   
</div>
</template>

<script>
import axios from "axios";
export default {
  name: 'Cabecera',
    data: function () {
        return {
            info:null,
            //items: this.$i18n.t('ok'),
        }
    },
    props:{
        ruta: String
    },

    localizacion: window.location,
    beforeMount: function () { 
   
    },
mounted: 

  function(){
    this.localizacion=window.location
    axios
        .get('https://cloud.movistar.es/webAyuda/Config.json')
      .then(response => {
        this.info = response.data.textos.cabecera;
      })
     
 },
 methods:{
     salir(){
         location.href=this.info.salir;
     }
 }

}

</script>

<style>
.link-active{
    cursor: pointer;
}
.enlacePdf{
    margin-left: 6px;
}
.color_search{
    fill:#019df4;

}
.altura_div{
        width: 100%;
    height: 80px;
    display: table;
}
.altura_no_div{

    height: 80px;
    display: table;
    width: 100%;

}
.alinedo_vertical{
    display: table-cell;
    vertical-align: middle;
}
.titulo{
    font-size: 2.5rem;
    line-height: 75px;
}
.titulo_header{
    font-size: 1.125rem;
    height: 80px;
}
.img_atras{
    width: 24px;
}
.aling_left{
    text-align: left;
}
.aling_right{
    text-align: right;
}
.m_left_10{
    margin-left: 240px;
}
.logo_cabecera{width: 60px;}
.margen_titulo{
    width: 200px;
}
@media (max-width: 1240px) {
    .alinedo_vertical{
        padding-left: 24px;
    }
    .margen_titulo {
    width: 176px;
    margin-left: 24px;
}
.enlacePdf {
    margin-right: 24px;
}
}
@media (max-width: 812px) {
    .titulo{
        font-size: 1.5rem;
        color: white !important;
    }
    .margen_titulo{
        width: 75px;
        color: white !important;
    }
    svg.colorFlecha {
    fill: white;
    }
.altura_div{
    height: 77px;
    padding:0px 12px;
    padding-right: 24px;
}
.altura_no_div{
    background-color: #019df4;
    margin-top: -2px;
      padding:0px 12px;
    
}
  .arriba,.abajo{
        border: 1px solid white !important;
  }
    .alinedo_vertical{
        padding-left: 24px;
    }
}
@media (max-width: 812px) {
.d-sm-none {
    display: none;
}
}
</style>